import React from "react"

import Img from "gatsby-image"

import styles from "./style.module.scss"

const VideoBlock = ({ videoSrcURL, videoTitle, ...props }) => {
	
	return (
		
		<section className={ styles.block }>
			<div className={ `${ styles.overlay }`}>
				<div className={ styles.content }>
					{ props.title &&
						React.createElement(
							props.titleTag, 
							{
								className: styles.title,
							}, 
							props.title,
						) 
					}
					{ props.text &&
						<div className={ props.columns } dangerouslySetInnerHTML={{ __html: props.text }}></div>
					}
				</div>
			</div>
			{ props.image && 
				<Img className={ styles.image } fluid={ props.image }/>
			}
			<div className={ styles.video }>
				<iframe src={videoSrcURL} title={videoTitle} frameborder="0" allow="autoplay; fullscreen" muted allowfullscreen></iframe>
			</div>
			
		</section>
		
	)
}

export default VideoBlock
