import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../layouts/"
import SEO from "../../components/seo/"
import Crumbs from "../../components/breadcrumb/"
import BackNav from "../../components/back-nav/"

import Split from "../../components/split/"
import TextBlock from "../../components/text-block/"
import LinkBlock from "../../components/link-block/"
import LogoBlock from "../../components/logo-block/"
import VideoBlock from "../../components/video-block/"

import { psColour, wlColour, siteColour } from "../../data/data.colours"

const PainesendPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			psBg: file(relativePath: { eq: "backgrounds/painesend-aerial-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			aboutBg: file(relativePath: { eq: "backgrounds/tree-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			farmsBg: file(relativePath: { eq: "backgrounds/snow-covered-fields.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			contactBg: file(relativePath: { eq: "backgrounds/chick-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			wlLogo: file(relativePath: { eq: "logos/wl-logo.svg" }) {
				publicURL
			}
			wlBg: file(relativePath: { eq: "backgrounds/aston41-exterior-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Painesend"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={psColour.hex}
					link={`/`}/>
				<Split>
					<VideoBlock
						title={`Painesend Farm LLP`}
						titleTag={ `h1` }
						text={`
							<p>Painesend Farm LLP is a family partnership that farms in excess of 1,500 acres over a number of holdings in Hertfordshire, Buckinghamshire and Northamptonshire. Painesend Farm is based just outside Tring in Hertfordshire and is the headquarters of the Partnership.</p>
						`}
						videoSrcURL={'https://player.vimeo.com/video/407129995?autoplay=1&loop=1&title=0&byline=0&portrait=0'}
						videoTitle={'PE The Movie 2019'}
						image={ data.psBg.childImageSharp.fluid } 
						/>
					<Split vertical={true}>
						<Split>
							<LinkBlock
								hex={siteColour.hex}
								link={`/painesend/about`}
								text={`About us`}
								background={ data.aboutBg.childImageSharp.fluid } 
								position={`45% 100%`} />
							<LinkBlock
								hex={psColour.hex}
								link={`/painesend/our-farms/`}
								text={`Our farms`}
								background={ data.farmsBg.childImageSharp.fluid } />
						</Split>
						<Split>
							<LinkBlock
								hex={siteColour.hex}
								link={`/painesend/contact`}
								text={`Contact`}
								background={ data.contactBg.childImageSharp.fluid } 
								position={`20% 60%`}/>
							<LogoBlock 
								colour={wlColour.slug}
								hex={wlColour.hex}
								brand={`W Lamb`}
								logo={ data.wlLogo.publicURL }
								background={ data.wlBg.childImageSharp.fluid }
								link={ `${process.env.GATSBY_SISTER_SITE}/w-lamb` }
								offsite/>
						</Split>
					</Split>
				</Split>	
			</Layout>
		</>
	)
}

export default PainesendPage